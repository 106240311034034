import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateActiveSelect} from '../../actions/contentSelection';
import {bindActionCreators} from 'redux';
import {getActiveSelectName, getActiveSelected} from '../../reducers/index';

export class SelectTogglerComponent extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(event) {
    const visible = event.target.name === this.props.visible ? '' : event.target.name;
    this.props.updateActiveSelect(visible);
  }

  render() {
    const {visible} = this.props;

    return(
      <div className="selector selector-nav">
        <div className="selectors">

          <div className="btn-group btn-group-toggle">
            <label className={'btn btn-secondary ' + (visible === 'artist' ? 'active' : '')}>
              <input type="radio"
                     name="artist"
                     autoComplete="off"
                     checked={visible === 'artist'}
                     onChange={this.handleToggle} /> Artist
            </label>
            <label className={'btn btn-secondary ' + (visible === 'type' ? 'active' : '')}>
              <input type="radio"
                     name="type"
                     autoComplete="off"
                     checked={visible === 'type'}
                     onChange={this.handleToggle} /> Category
            </label>
            <label className={'btn btn-secondary ' + (visible === 'tag' ? 'active' : '')}>
              <input type="radio"
                     name="tag"
                     autoComplete="off"
                     checked={visible === 'tag'}
                     onChange={this.handleToggle} /> Tag
            </label>
            <label className={'btn btn-secondary ' + (visible === 'location' ? 'active' : '')}>
              <input type="radio"
                     name="location"
                     autoComplete="off"
                     checked={visible === 'location'}
                     onChange={this.handleToggle} /> Location
            </label>
            <label className={'btn btn-secondary ' + (visible === 'gallery' ? 'active' : '')}>
              <input type="radio"
                     name="gallery"
                     autoComplete="off"
                     checked={visible === 'gallery'}
                     onChange={this.handleToggle} /> Gallery
            </label>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    visible: getActiveSelectName(state),
    selected: getActiveSelected(state),
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateActiveSelect
  }, dispatch)
};

const SelectToggler = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectTogglerComponent);

export default SelectToggler;