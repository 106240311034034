import settings from "../../settings";

/***
 * Map Options
 * @param options
 * @returns {{label: *, value: *}[]}
 */
export const mapOptions = options => {
  return Object.keys(options).map(key => {
    return {label: options[key].name, value: options[key].id}
  });
};

/***
 * Map Beneficiary Options
 * @param options
 * @returns {{label: *, value: *}[]}
 */
export const mapBeneficiaryOptions = options => {
  return Object.keys(options).map(key => {
    return {label: options[key].short_name, value: options[key].id}
  });
};

/***
 * Map Title Options
 * @param options
 * @returns {{label: *, value: string}[]}
 */
export const mapTitleOptions = options => {
  return Object.keys(options).map(key => {
    return {label: options[key].title, value: options[key].id}
  });
};

/***
 * Map Location Options
 * @param options
 * @returns {*[]}
 */
export const mapLocationOptions = options => {
  return  Object.keys(options).reduce((current, next) => {
    const rooms = options[next].rooms.map(room => {
      return {value: room.id + '-' + options[next].id, id: room.id, label: room.name, parent: options[next].name};
    });
    return [...current, {value: options[next].id, id: options[next].id, label: options[next].name, category: true}, ...rooms];
  }, []);
};

/***
 * Map Category Options
 * @param options
 * @returns {*[]}
 */
export const mapCategoryOptions = options => {
  return  Object.keys(options).reduce((current, next) => {
    const categories = options[next].categories.map(category => {
      return {value: category.id + '-' + options[next].id, id: category.id, label: category.name, parent: options[next].name};
    });
    return [...current, {value: options[next].id, id: options[next].id, label: options[next].name, category: true}, ...categories];
  }, []);
};

/***
 * Fetch Content
 * @param params
 * @param token
 * @param endpoint
 * @returns {Promise<Response>}
 */
export const fetchContent = (params, token, endpoint) => {
  return fetch(endpoint + '?' + params, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      "Authorization": 'Bearer ' + token
    }
  });
};

/***
 * Post Content
 * @param content
 * @param selection
 * @param token
 * @returns {Promise<Response>}
 */
export const postContent = (content, selection, token) => {
  const endpoint = settings.internal.saveEndpoint + '/' + selection.id;
  const body = getFormData(content);

  return fetch(endpoint, {
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + token,
    },
    body,
    method: 'POST',
  })
};

/***
 * Get Parents
 * @param items
 * @returns {*}
 */
const getParents = (items) => {
  return items.filter(item => !item.parent);
};

/***
 * Get Children
 * @param items
 * @returns {*}
 */
const getChildren = (items) => {
  return items.filter(item => item.parent);
};

/***
 * Map Selection To Query Parameters
 * @param selected
 */
export const mapSelectionToParams = (selected) => {

  // map location selection
  const house = selected.location ? getParents(selected.location) : [];
  const room = selected.location ? getChildren(selected.location) : [];
  const categories = selected.type ? getParents(selected.type) : [];
  const subCategories = selected.type ? getChildren(selected.type) : [];
  const beneficiary = selected.beneficiary ? selected.beneficiary : [];

  let params = {
    select: selected.category,
    a: selected.artist,
    t: selected.tag,
    h: house,
    r: room,
    o: categories,
    m: subCategories,
    g: selected.gallery,
    b: beneficiary

  };

  // select with a search term
  if (selected.search) {
    params = {
      search: selected.search,
      select: selected.category
    }
  }

  return params;
};

export const buildParams = (params) => {
  const formattedParams = formatParams(params);
  return new URLSearchParams(formattedParams)
};

/***
 * Reduce Parameter Values
 * @param params
 * @returns {*}
 */
const reduceParams = (params) => {
  if (params.constructor === Array) {
    return params.reduce((p, n, i) => {
      return i > 0 ? p + settings.delimiter + n : n
    }, '');
  }
  else {
    return params;
  }
};

/***
 * Get Encoded Query Parameters
 * @param mappedParams
 * @returns {{}}
 */
export const formatParams = (mappedParams) => {

  return Object.keys(mappedParams).reduce((accumulator, current) => {

    if (mappedParams[current].constructor === Array) {
      // query param is an array of selected ids or string values
      const values = mappedParams[current].map(i => (i.id !== undefined && i.id !== null) ? i.id : i.value);
      accumulator[current] = reduceParams(values);
    }

    else if (mappedParams[current]) {
      // query param is a key, value pair
      accumulator[current] = mappedParams[current];
    }

    else {
      throw new Error('Invalid parameter structure');
    }
    return accumulator;
  }, {});
};

/***
 * Get Form Data
 * @param content
 * @returns {FormData}
 */
const getFormData = (content) => {
  const formData = new FormData();

  if (!content) {
    return formData;
  }

  Object.keys(content).forEach(key => {
    formData.append(key, content[key]);
  });

  formData.append("_method", "put");
  return formData;
};