import React from 'react';
import './ContactPage.css';

export const ContactPage = () => {
  return (
    <div className="content wrap">
      <div className="ContactPage">
        <strong>Contact</strong>
        <p>
          For technical support, questions, or concerns: please contact <a href="mailto:help@cvcartconsulting.com">help@cvcartconsulting.com</a> or call Caroline Cox at 978.853.5117.
        </p>

        <strong>Privacy Statement</strong>
        <p>
          This website was made exclusively for the private use of the Kennedy family and their approved contractors and vendors.
        </p>
        <p>
          No information is gathered, sold, or distributed through the use of this website.
        </p>

        <strong>Disclaimer</strong>
        <p>
          The information herein was collected through conversations and documentation provided by the Kennedy family and through their vendors.
        </p>

        <strong>With Information Provided By</strong>
        <div className="row galleries">
          <div className="col-sm-6">
            Bill & Wanita Kennedy<br/>
            A Street Frames | Cambridge, MA<br/>
            Atelier | Newport, RI<br/>
            Chase Young Gallery | Boston, MA<br/>
            Copley Society of Art | Boston, MA<br/>
            DTR Modern Galleries | Boston, MA<br/>
            Galerie Alain Lacaze | Montreal, QC<br/>
            Haley & Steele and Guido Frames | Boston, MA<br/>
            International Poster Gallery | Boston, MA<br/>
            Jessica Hagen Fine Art | Newport, RI<br/>
            John Rosselli Antiques | New York, NY<br/>
            Jules Place | Boston, MA<br/>
          </div>
          <div className="col-sm-6">
            Lanoue Fine Art | Boston, MA<br/>
            M Fine Arts Galerie | Boston, MA<br/>
            Martin Lawrence Galleries | Boston, MA<br/>
            North Water Gallery | Martha’s Vineyard, MA<br/>
            Paula Estey Gallery | Newburyport, MA<br/>
            Quidley & Co. | Boston, MA and Naples, FL<br/>
            Spring Bull Studio & Gallery | Newport, RI<br/>
            shopSCAD | Savannah, GA<br/>
            Vose Galleries | Boston, MA<br/>
            The Wicker Lady, Charlie Wagner | Needham, MA<br/>
            Wild Horse Gallery | Steamboat Springs, CO<br/>
          </div>
        </div>

        Copyright © CVC Art Consulting, 2019<br/>
        <a href="http://www.cvcartconsulting.com" target="_blank">www.cvcartconsulting.com</a><br/>
        Salem, MA<br/>
        All rights reserved<br/>

        <br/>

        With the support of Zen Zone Digital<br/>
        <a href="http://www.zenzonedigital.com" target="_blank">www.zenzonedigital.com</a><br/>
        Brooklyn, NY<br/>
      </div>
    </div>
  )
};

export default ContactPage;