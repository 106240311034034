import {contentDetailReducer, contentReducer} from "./content";
import contentSelectionReducer from './contentSelection';
import authReducer from "./auth";
import reducerRegistry from "../../utils/reducerRegistry";
import * as fromContentSelection from './contentSelection';
import * as fromAuth from './auth';
import * as fromContent from './content';
import settings from "../../settings";

const authState = {
  endpoints: {
    login: settings.endpoint.login,
    logout: settings.endpoint.logout,
    register: settings.endpoint.register,
    forgotPassword: settings.endpoint.forgotPassword,
    updatePassword: settings.endpoint.updatePassword
  }
};

export const registerReducers = () => {
  reducerRegistry.register('auth', authReducer, authState);
  reducerRegistry.register('content', contentReducer);
  reducerRegistry.register('contentDetail', contentDetailReducer);
  reducerRegistry.register('contentSelection', contentSelectionReducer);
};

// Content Selection selectors
export const getActiveSelectName = state =>
  fromContentSelection.getActiveSelectName(state);

export const getActiveSelectOptions = state =>
  fromContentSelection.getActiveSelectOptions(state);

export const getSelected = state =>
  fromContentSelection.getSelected(state);

export const getActiveSelected = state =>
  fromContentSelection.getActiveSelected(state);

export const getActiveSelectPlaceholder = state =>
  fromContentSelection.getActiveSelectPlaceholder(state);

export const getSearchTerm = state =>
  fromContentSelection.getSearchTerm(state);

export const getSelectedCategories = state =>
    fromContentSelection.getSelectedCategories(state);

export const getEmptySelection = () =>
    fromContentSelection.getEmptySelection();

// Auth selectors
export const getAuthToken = state =>
  fromAuth.getAuthToken(state);

// Content selectors

export const getCollection = state =>
  fromContent.getCollection(state);

export const getLoading = state =>
  fromContent.getLoading(state);

export const getClearCache = state =>
  fromContent.getClearCache(state);

// Content detail selectors

export const getContentDetail = state =>
  fromContent.getContentDetail(state);

export const getBeneficiaries = state =>
  fromContent.getBeneficiaries(state);