import React, {Component} from 'react';
import {getAbsoluteHeight} from '../../../utils/windowUtils';
import './Page.css';

export const staticPage = (WrappedComponent, rest) => {

  return class extends Component {

    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const headerHeight = getAbsoluteHeight('header');
      this.height = window.innerHeight - headerHeight;
      this.forceUpdate();
    }

    render() {
      return (
        <React.Fragment>
          <div className="static-screen" style={{height: this.height}}>
            <WrappedComponent props={this.props} {...rest} />
          </div>
        </React.Fragment>
      );
    }
  }
};