import { combineReducers, createStore, applyMiddleware } from 'redux';
import reducerRegistry from './reducerRegistry';
import thunk from "redux-thunk";

const configureStore = () => {
  const initialState = {};

  const combine = (reducers) => {
    const reducerNames = Object.keys(reducers);
    Object.keys(initialState).forEach(item => {
      if (reducerNames.indexOf(item) === -1) {
        reducers[item] = (state = null) => state;
      }
    });
    return combineReducers(reducers);
  };

  const store = createStore(() => {}, initialState, applyMiddleware(thunk));

  reducerRegistry.setChangeListener(reducers => {
    store.replaceReducer(combine(reducers));
  });

  return store;
};

export default configureStore;