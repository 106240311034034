export const getAbsoluteHeight = (el) => {
  el = (typeof el === 'string') ? document.querySelector(el) : el;

  if (!el) {
    return null;
  }

  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles['marginTop']) +
    parseFloat(styles['marginBottom']);
  return Math.ceil(el.offsetHeight + margin);
};

export const getScrollTop = () =>{
  if(typeof window.pageYOffset!= 'undefined'){
    //most browsers except IE before #9
    return window.pageYOffset;
  }
  else{
    var B= document.body; //IE 'quirks'
    var D= document.documentElement; //IE with doctype
    D= (D.clientHeight)? D: B;
    return D.scrollTop;
  }
};