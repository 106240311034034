import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Logout} from '../Auth';
import {logout} from '../../actions/auth/rest.api';
import './MainNav.css';
import {getAuthenticated} from "../../reducers/auth";
import {getRole} from "../../reducers/auth";

class MainNavComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {showTools: false};
    this.toggleToolsMenu = this.toggleToolsMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({showTools: false});
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleToolsMenu() {
    this.setState({showTools: !this.state.showTools});
  }

  render() {
    const isDesignerRole = (this.props.role.value) === "0" ? true : false;

    return (
      <React.Fragment>
        { this.props.authenticated &&
          <React.Fragment>
            {this.props.showFullNav &&
            <div className="tools-menu">
              <a onClick={this.toggleToolsMenu}>Explore</a>
              {this.state.showTools &&
              <div ref={this.setWrapperRef} className="tools-dropdown">
                <Link onClick={this.toggleToolsMenu} to="/timeline">Timeline</Link>
                {!isDesignerRole && <Link onClick={this.toggleToolsMenu} to="/utilities">Reports</Link>}
                <Link onClick={this.toggleToolsMenu} to="/contact">Contact</Link>
              </div>
              }
            </div>
            }
            <div className="static-menu">
              <Logout action={logout} />
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    authenticated: getAuthenticated(state),
    role: getRole(state)
  }
};

const mapDispatchToProps = dispatch => {
  return {dispatch}
};

const MainNav = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNavComponent);

export default MainNav;