import React, {Component} from 'react';
import 'react-select/dist/react-select.css';
import '../selectors/Selector.css';
import {connect} from "react-redux";
import {clearCache} from "../../actions/content";
import {updateCategorySelection, updateSelection} from '../../actions/contentSelection';
import settings from "../../../settings";
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {getSelectedCategories, getEmptySelection} from "../../reducers";
import {categoryTypes} from '../../reducers/contentSelection';

class SelectModeComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);

    this.categoryTypes = categoryTypes;

    this.state = {
      selected: [],
      allSelected: false,
      accessoriesSelected: false,
      artSelected: false,
      furnitureSelected: false
    };
  }

  handleSelect(category) {

    let selected = [];

    if (this.state.selected.findIndex(s => s.value === category.value) !== -1) {
      selected = this.state.selected.filter(s => s.value !== category.value);

      if (this.state.allSelected) {
        this.setState({allSelected: false});
      }
    }
    else {
      selected = this.state.selected.concat(category);
    }

    switch (category.value) {
      case 1:
        this.setState({artSelected: !this.state.artSelected, selected});
        break;
      case 2:
        this.setState({accessoriesSelected: !this.state.accessoriesSelected, selected});
        break;
      case 3:
        this.setState({furnitureSelected: !this.state.furnitureSelected, selected});
        break;
    }

    this.props.updateCategorySelection(selected);
  }

  handleSelectAll() {
    this.setState({
      selected: this.state.allSelected ? [] : this.categoryTypes,
      allSelected: !this.state.allSelected,
      accessoriesSelected: !this.state.allSelected,
      artSelected: !this.state.allSelected,
      furnitureSelected: !this.state.allSelected
    });
    this.props.updateCategorySelection(this.categoryTypes);
  }

  handleSubmit() {
    if (!this.state.selected.length) {
     this.handleSelectAll();
    }

    const selected = {...this.props.selected, category: this.props.selectedCategories};

    this.props.clearCache();
    this.props.updateSelection(selected);
    this.props.history.push('/' + settings.contentSlug);
  }

  render() {
    const btnClasses = 'btn btn-select btn-block';

    return(
      <div className="category-selector-container">
        <button onClick={() => this.handleSelectAll()} className={this.state.allSelected ? btnClasses + ' active' : btnClasses}>
          {this.state.allSelected && <span>Deselect All Items</span>}
          {!this.state.allSelected && <span>All Items</span>}
        </button>
        <button onClick={() => this.handleSelect({value: 1, label: 'Art'})} className={this.state.artSelected ? btnClasses + ' active' : btnClasses}>Art</button>
        <button onClick={() => this.handleSelect({value: 2, label: 'Accessories'})} className={this.state.accessoriesSelected ? btnClasses + ' active' : btnClasses}>Accessories</button>
        <button onClick={() => this.handleSelect({value: 3, label: 'Furniture'})} className={this.state.furnitureSelected ? btnClasses + ' active' : btnClasses}>Furniture</button>
        <button onClick={this.handleSubmit} className="btn btn-brand btn-submit">Continue</button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCategories: getSelectedCategories(state),
    selected: getEmptySelection()
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateCategorySelection,
    clearCache,
    updateSelection
  }, dispatch)
};

const SelectMode = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectModeComponent);

export default withRouter(SelectMode);