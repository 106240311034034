import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const ProtectedRoute = ({path, component: Component, authorized, redirect, exact}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => authorized === true
        ? <Component {...props} />
        : <Redirect to={{pathname: redirect, state: {from: props.location}}} />}
    />
  )
};

export default ProtectedRoute;