import {fetchContent, postContent, mapSelectionToParams, buildParams} from "../api";
import settings from '../../settings';
import {clearAuth} from "./index";
import {updateOptions} from "./contentSelection";

/***
 * Content (list) actions
 */
export const REQUEST_CONTENT_PENDING = 'REQUEST_CONTENT_PENDING';
export const REQUEST_CONTENT_SUCCESS = 'REQUEST_CONTENT_SUCCESS';
export const REQUEST_CONTENT_FAIL = 'REQUEST_CONTENT_FAIL';
export const CLEAR_CONTENT = 'CLEAR_CONTENT';
export const CLEAR_CACHE = 'CLEAR_CACHE';

export const requestContent = (selection, token, endpoint) => {
  return async dispatch => {
    dispatch(requestContentPending());
    try {
      const params = mapSelectionToParams(selection);
      const formattedParams = buildParams(params);
      const response = await fetchContent(formattedParams, token, endpoint || settings.internal.contentEndpoint);

      if (!response.ok) {
        if (response.status === 401) {
          clearAuth(dispatch);
        }
        else {
          dispatch(requestContentFail(response.message));
        }
      }
      else {
        const json = await response.json();
        const content = json.data ? json.data : json[0];

        dispatch(requestContentSuccess(content));

        if (json.data.options) {
          dispatch(updateOptions(json.data.options));
        }
      }
    }
    catch (error) {
      dispatch(requestContentFail(error || 'Request content failed'));
    }
  };
};

export const requestContentPending = () => {
  return {type: REQUEST_CONTENT_PENDING}
};

export const requestContentSuccess = data => {
  return {
    type: REQUEST_CONTENT_SUCCESS,
    data
  }
};

export const requestContentFail = error => {
  return {
    type: REQUEST_CONTENT_FAIL,
    payload: error
  }
};

export const clearContent = () => {
  return {
    type: CLEAR_CONTENT,
  }
};

export const clearCache = () => {
  return {
    type: CLEAR_CACHE,
  }
};


/***
 * Content (detail) actions
 */
export const REQUEST_CONTENT_DETAIL_PENDING = 'REQUEST_CONTENT_DETAIL_PENDING';
export const REQUEST_CONTENT_DETAIL_SUCCESS = 'REQUEST_CONTENT_DETAIL_SUCCESS';
export const REQUEST_CONTENT_DETAIL_FAIL = 'REQUEST_CONTENT_DETAIL_FAIL';
export const CLEAR_CONTENT_DETAIL = 'CLEAR_CONTENT_DETAIL';

export const requestContentDetail = (selection, token, endpoint) => {
  return async dispatch => {
    dispatch(requestContentDetailPending());
    try {
      const params = mapSelectionToParams(selection);
      const formattedParams = buildParams(params);
      const response = await fetchContent(formattedParams, token, endpoint || settings.internal.contentEndpoint);

      if (!response.ok) {
        if (response.status === 401) {
          clearAuth(dispatch);
        }
        else {
          dispatch(requestContentDetailFail(response.message));
        }
      }
      else {
        const json = await response.json();
        const content = json.data ? json.data : json[0];

        dispatch(requestContentDetailSuccess(content));

        if (json.data.options) {
          dispatch(updateOptions(json.data.options));
        }
      }
    }
    catch (error) {
      dispatch(requestContentDetailFail(error || 'Request content failed'));
    }
  };
};

export const requestContentDetailPending = () => {
  return {type: REQUEST_CONTENT_DETAIL_PENDING}
};

export const requestContentDetailSuccess = data => {
  return {
    type: REQUEST_CONTENT_DETAIL_SUCCESS,
    payload: data.collection[0],
    beneficiaries: data.beneficiaries_list
  }
};

export const requestContentDetailFail = error => {
  return {
    type: REQUEST_CONTENT_DETAIL_FAIL,
    error
  }
};

export const clearContentDetail = () => {
  return {
    type: CLEAR_CONTENT_DETAIL,
  }
};

/***
 * Content (save) actions
 */
export const SAVE_CONTENT_POSTING = 'SAVE_CONTENT_POSTING';
export const SAVE_CONTENT_SUCCESS = 'SAVE_CONTENT_SUCCESS';
export const SAVE_CONTENT_FAILED = 'SAVE_CONTENT_FAILED';

export const saveContent = (content, selection, token) => {
  return async dispatch => {
    dispatch(savePosting());

    try {
      const response = await postContent(content, selection, token, settings.internal.saveEndpoint + '/' + content.id);

      if (!response.ok) {
        if (response.status === 401) {
          clearAuth(dispatch);
        }
        else {
          dispatch(saveFailed(response.message));
        }
      }
      else {
        const json = await response.json();
        dispatch(saveSuccess(json.data));
      }
    }
    catch (error) {
      dispatch(saveFailed(error || 'Save content failed'));
    }
  }
};

export const savePosting = () => {
  return {type: SAVE_CONTENT_POSTING}
};

export const saveSuccess = (data) => {
  return {type: SAVE_CONTENT_SUCCESS, payload: data.collection[0]}
};

export const saveFailed = (error) => {
  return {
    type: SAVE_CONTENT_FAILED,
    payload: error
  }
};