import React from 'react';
import {Login} from '../Auth';
import {login} from '../../actions/auth/rest.api';
import {staticPage} from "../Page/Page";
import './Home.css';

const HomeComponent = () => {
  return (
    <React.Fragment>
      <div className="home-carousel"></div>
      <div className="login-screen">
        <Login action={login} forgotPasswordLink="https://admin.wkcollection.com/dashboard/password/reset" />
      </div>
    </React.Fragment>
  );
};

const Home = staticPage(HomeComponent);

export default Home;