
/***
 * Content selection actions
 */
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const UPDATE_ACTIVE_SELECT = 'UPDATE_ACTIVE_SELECT';
export const UPDATE_CATEGORY_SELECTION = 'UPDATE_CATEGORY_SELECTION';
export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
export const RECEIVE_OPTIONS_FAILED = 'RECEIVE_OPTIONS_FAILED';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const ADD_TIMELINE_ANCHOR = 'ADD_TIMELINE_ANCHOR';
export const REMOVE_TIMELINE_ANCHOR = 'REMOVE_TIMELINE_ANCHOR';

export const updateOptions = options => {
  return {
    type: UPDATE_OPTIONS,
    options
  }
};

export const updateActiveSelect = name => {
  return {
    type: UPDATE_ACTIVE_SELECT,
    name
  }
};

export const updateSelection = selection => {
  return {
    type: UPDATE_SELECTION,
    selection
  }
};

export const updateCategorySelection = selection => {
  return {
    type: UPDATE_CATEGORY_SELECTION,
    selection
  }
};

export const updateSearchTerm = term => {
  return {
    type: UPDATE_SEARCH_TERM,
    term
  }
};

export const addAnchor = (anchor) => {
  return {
    type: ADD_TIMELINE_ANCHOR,
    payload: anchor
  }
};

export const removeAnchor = () => {
  return {
    type: REMOVE_TIMELINE_ANCHOR,
  }
};