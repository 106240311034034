import React from 'react';
import settings from "../../../settings";

const ConditionDocumentList = (props) => {

  let documents = [];

  if (props.docs) {
    documents = props.docs.map((doc, index) => {
      return (
        <li key={index}>
          <a target="_blank" href={settings.internal.documentEndpoint + doc.file_path}>{doc.display_name || doc.name}</a>
        </li>
      );
    });
  }

  return (
    <ul>{documents}</ul>
  );
};

export default ConditionDocumentList;