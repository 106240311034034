import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './utils/registerServiceWorker';
import configureStore from "./utils/configureStore";
import Root from './core/components/Root/Root';
import {registerReducers} from './core/reducers';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

const store = configureStore(() => {});
registerReducers();


ReactDOM.render(
  <Root store={store} />,
  document.getElementById('root'));
registerServiceWorker();
