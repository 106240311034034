import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateSearchTerm} from '../../actions/contentSelection';
import {bindActionCreators} from 'redux';
import {requestContent} from '../../actions/content';
import {getSelected, getSearchTerm, getAuthToken} from '../../reducers/index';
import FontAwesome from 'react-fontawesome';
import 'react-select/dist/react-select.css';
import '../selectors/Selector.css';
import settings from "../../../settings";
import {withRouter} from 'react-router-dom';
import {DebounceInput} from 'react-debounce-input';

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleShowInput = this.handleShowInput.bind(this);
    this.state = {showInput: false};
  }

  handleInput(event) {
    this.props.updateSearchTerm(event.target.value);

    if (this.props.location.pathname !== '/' + settings.contentSlug) {
      this.props.history.push('/' + settings.contentSlug);
    }
  }

  handleShowInput() {
    this.setState({showInput: true});
    setTimeout(() => this.input.focus(), 1);
  }

  handleBlur() {
    if (!this.props.searchTerm.length) {
      this.setState({showInput: false});
    }
  }

  render() {
    return(
      <div className="search-container">
        {(window.innerWidth < 768 || (!this.state.showInput && !this.props.searchTerm.length)) &&
        <FontAwesome name="search" onClick={this.handleShowInput} className="search-icon" />
        }
        {(this.props.searchTerm.length || this.state.showInput) &&
        <DebounceInput
          placeholder="Search catalog"
          value={this.props.searchTerm}
          inputRef={(input) => { this.input = input; }}
          minLength={2}
          debounceTimeout={300}
          onChange={this.handleInput}
          onBlur={this.handleBlur} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: getSearchTerm(state),
    selected: getSelected(state),
    token: getAuthToken(state)
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    requestContent,
    updateSearchTerm
  }, dispatch)
};

const Search = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent);

export default withRouter(Search);