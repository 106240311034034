import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {updateSelection} from '../../actions/contentSelection';
import {requestContent} from '../../actions/content';
import {
  getSelected,
  getActiveSelectName,
  getActiveSelectOptions,
  getActiveSelected,
  getActiveSelectPlaceholder,
  getAuthToken
} from '../../reducers/index';
import 'react-select/dist/react-select.css';
import '../selectors/Selector.css';
import settings from "../../../settings";
import {bindActionCreators} from 'redux';
import Select from "../Select/Select";

export class ContentSelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {visible: ''};
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.state.visible) {
      this.selectRef.focus();
      this.setState({visible: nextProps.visible});
    }
  }

  handleSelect(event) {
    const selected = {...this.props.selected, [this.props.visible]: event};

    this.props.updateSelection(selected);
    this.props.requestContent(selected, this.props.token);

    if (this.props.location.pathname !== '/' + settings.contentSlug) {
      this.props.history.push('/' + settings.contentSlug);
    }
  }

  render() {
    const {options, activeSelected, visible, placeholder} = this.props;

    return(
      <div className="selector selector-dropdown">
        <div className="selectors row">
          <Select options={options}
                  selected={activeSelected}
                  name={visible}
                  placehlder={placeholder}
                  handleSelect={e => this.handleSelect(e)}
                  refFnc={ref => this.selectRef = ref}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    visible: getActiveSelectName(state),
    options: getActiveSelectOptions(state),
    selected: getSelected(state),
    activeSelected: getActiveSelected(state),
    placeholder: getActiveSelectPlaceholder(state),
    token: getAuthToken(state)
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateSelection,
    requestContent
  }, dispatch);
};

const ContentSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentSelectComponent);

export default withRouter(ContentSelect);