import React, {Fragment} from 'react';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';
import './Select.css';

const Select = props => {
  const {options, selected, name, placeholder, handleSelect, refFnc} = props;

  const categoryRenderOption = option => (
    <Fragment>
      {option.category &&
      <strong>{option.label}</strong>
      }
      {!option.category &&
      <span>{option.label}</span>
      }
    </Fragment>
  );

  return(
    <ReactSelect
      name={name}
      ref={ref => refFnc(ref)}
      placeholder={placeholder}
      multi={true}
      autoFocus={true}
      openOnFocus={true}
      removeSelected={false}
      value={[...selected]}
      onChange={e => handleSelect(e)}
      options={options}
      optionRenderer={categoryRenderOption}
    />
  );
};

export default Select;