import React from 'react';
import App from '../App/App';
import {Provider} from 'react-redux';
import PropTypes from 'prop-types';
import {Router, Route} from 'react-router';
import history from '../../../utils/history';

const Root = ({store}) => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={App} />
      </Router>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;