import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {clearContent} from "../../actions/content";
import {updateSearchTerm} from "../../actions/contentSelection";
import {requestContent} from "../../actions/content";
import {withRouter} from 'react-router-dom';
import {getSelected} from "../../reducers/contentSelection";
import {getAuthToken} from "../../reducers/auth";

export const withSelectRedirect = (WrappedComponent, rest) => {

  class SelectRedirectComponent extends Component {
    constructor(props) {
      super(props);
      this.handleSelectRedirect = this.handleSelectRedirect.bind(this);
    }

    handleSelectRedirect(selection) {
      let selected = selection || this.props.selected || {};
      delete selected.search;
      this.props.updateSearchTerm('');
      this.props.clearContent();
      this.props.requestContent(selected, this.props.token);

      if (this.props.location.pathname !== this.props.redirect) {
        this.props.history.push(this.props.redirect);
      }
    }

    render() {
      return (
        <WrappedComponent handleSelectRedirect={this.handleSelectRedirect} {...rest} />
      );
    }
  }

  const mapStateToProps = state => {
    return {
      selected: getSelected(state),
      token: getAuthToken(state)
    }
  };

  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
      requestContent,
      updateSearchTerm,
      clearContent
    }, dispatch)
  };

  const SelectRedirect = connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectRedirectComponent);

  return withRouter(SelectRedirect);

};