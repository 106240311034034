import {
  UPDATE_SELECTION,
  UPDATE_ACTIVE_SELECT,
  UPDATE_CATEGORY_SELECTION,
  UPDATE_OPTIONS,
  UPDATE_SEARCH_TERM,
  ADD_TIMELINE_ANCHOR,
  REMOVE_TIMELINE_ANCHOR
} from "../actions/contentSelection";
import {mapOptions, mapLocationOptions, mapCategoryOptions} from "../api/index";

export const categoryTypes = [
  {value: 1, label: 'Art'},
  {value: 2, label: 'Accessories'},
  {value: 3, label: 'Furniture'}
];

export const emptySelection = () => {
  return {
    category: [],
    location: [],
    artist: [],
    type: [],
    tag: [],
    gallery: []
  }
};

export const defaultSelectionState = {
  selected: emptySelection(),
  selectedCategories: categoryTypes,
  activeSelect: '',
  options: {},
  searchTerm: '',
  anchor: ''
};

// Reducer

const contentSelectionReducer = (state = defaultSelectionState, action) => {
  switch(action.type) {
    case UPDATE_SELECTION:
      return {...state, selected: action.selection, searchTerm: ''};
    case UPDATE_ACTIVE_SELECT:
      return {...state, activeSelect: action.name};
    case UPDATE_CATEGORY_SELECTION:
      return {...state, selectedCategories: action.selection};
    case UPDATE_OPTIONS:
      return {...state, options: action.options};
    case UPDATE_SEARCH_TERM:
      return {...state, searchTerm: action.term, selected: emptySelection()};
    case ADD_TIMELINE_ANCHOR:
      return {...state, anchor: action.payload};
    case REMOVE_TIMELINE_ANCHOR:
      return {...state, anchor: ''};
    default:
      return state;
  }
};

export default contentSelectionReducer;

// Selectors

export const getActiveSelectName = state => {
  return state.contentSelection.activeSelect;
};

export const getActiveSelectOptions = state => {

  if (!Object.keys(state.contentSelection.options).length) {
    return [];
  }

  switch(state.contentSelection.activeSelect) {
    case 'type':
      return mapCategoryOptions(state.contentSelection.options['categories']);
    case 'artist':
      return mapOptions(state.contentSelection.options['artists']);
    case 'tag':
      return mapOptions(state.contentSelection.options['tags']);
    case 'location':
      return mapLocationOptions(state.contentSelection.options['locations']);
    case 'gallery':
      return mapOptions(state.contentSelection.options['galleries']);
    default:
      return [];
  }
};

export const getSelected = state => {
  return state.contentSelection.selected;
};

export const getActiveSelected = state => {
  return state.contentSelection.activeSelect && state.contentSelection.selected[state.contentSelection.activeSelect];
};

export const getActiveSelectPlaceholder = state => {
  return 'Select ' + state.contentSelection.activeSelect;
};

export const getSelectedCategories = state => {
  return state.contentSelection.selectedCategories;
};

export const getSearchTerm = state => {
  return state.contentSelection.searchTerm;
};

export const getAnchor = state => {
  return state.contentSelection.anchor
};

export const getEmptySelection = () => {
  return { ...emptySelection() };
};