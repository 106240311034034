import React, {Component} from 'react';
import settings from '../../../settings';
import './Modal.css';
import Slider from "react-slick";
import FontAwesome from 'react-fontawesome';
import ReactImageMagnify from 'react-image-magnify';

class Modal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      slideIndex: props.initialIndex,
      updateCount: 0
    };
  }

  getImage(url) {
    return new Promise((resolve) => {
      const image = new Image;

      image.onload = function(e) {
        const imgWidth = e.target.width,
          imgHeight = e.target.height,
          winWidth = window.innerWidth * .7,
          winHeight = window.innerHeight * .6,
          ratio = Math.min(winWidth/imgWidth, winHeight/imgHeight);

        resolve({width: e.target.width, height: e.target.height, ratio: ratio});
      };

      image.onerror = function(e) {
        console.log(e);
        resolve({width: 0, height: 0, ratio: 0});
      };

      image.src = url;
    });
  }

  loadImages() {
    let imagePromises = [];

    if (this.props.item.images) {
      const imageKeys = Object.keys(this.props.item.images);
      imagePromises = imageKeys.map((image, index) => {
        const imageData = this.props.item.images[image]['MO'][0];
        const img = imageData ? settings.internal.imageEndpoint + imageData.file_path : '/assets/theme/KC-No-Image.png';
        return this.getImage(img);
      });
    }

    return Promise.all(imagePromises);
  }

  async componentWillMount() {
    if (this.props.item.images) {
      const images = await this.loadImages();
      this.setState({images});
    }
    else {
      return Promise.resolve();
    }
  }

  render() {

    if (this.state.images.length === 0) {
      return false;
    }

    const sliderSettings = {
      dots: true,
      infinite: true,
      initialSlide: this.props.initialIndex,
      speed: 50,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    let imgs = [];
    const imageKeys = Object.keys(this.props.item.images);

    if (this.props.item.images) {
      imgs = imageKeys.map((image, index) => {

        const heroData = this.props.item.images[image]['MO'][0];
        const label = heroData ? heroData.name : '';
        const hero = heroData ? settings.internal.imageEndpoint + heroData.file_path : '/assets/theme/KC-No-Image.png';

        const largeData = this.props.item.images[image]['2K'][0];
        const large = largeData ? settings.internal.imageEndpoint + largeData.file_path : '/assets/theme/KC-No-Image.png';

        return (
          <div key={index}>
            <div className="zoom-container" style={{width: this.state.images[index].width * this.state.images[index].ratio}}>
              <ReactImageMagnify {...{
                smallImage: {
                  isFluidWidth: true,
                  src: hero
                },
                largeImage: {
                  src: large,
                  width: this.state.images[index].width * 2,
                  height: this.state.images[index].height * 2,
                },
                enlargedImagePosition: 'over'
              }} />

              <div className="type">{label}</div>
            </div>
          </div>
        );
      });
    }

    return (
      <div id="modal-window">
        <a className="close-btn" onClick={(e) => this.props.onModalClose(e)}><FontAwesome name="close" className="btn-close" /></a>

        <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
          {imgs}
        </Slider>

        <div className="title">{this.props.item.title}</div>
      </div>
    )
  }
};

export default Modal;