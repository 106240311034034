import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {updateSelection} from '../../actions/contentSelection';
import {requestContent} from '../../actions/content';
import settings from '../../../settings';
import {emptySelection, getSelected} from '../../reducers/contentSelection';
import './Tags.css';
import {getAuthToken} from "../../../features/timeline/reducer";

export class TagsComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  mapTagToOptions(tag) {
    return [{
      label: tag.name,
      value: tag.id
    }];
  }

  handleSelect(event, index) {
    event.preventDefault();

    const selected = {
      ...emptySelection(),
      tag: this.mapTagToOptions(this.props.tags[index])
    };
    this.props.updateSelection(selected);

    // return to list view if on detail view
    if (this.props.detailVisible) {
      this.props.history.push('/' + settings.contentSlug);
    }
  }

  render() {
    let tags = [];

    if (this.props.tags){
      tags = this.props.tags.map((tag, index) => {
        return (
          <span key={index} onClick={(e) => this.handleSelect(e, index)} className="tag">
            {tag.name}{index === this.props.tags.length - 1 ?  '' : ', '}
          </span>
        );
      });
    }

    return(
      <React.Fragment>
        {tags.length > 0 &&
        <div className="tags row">
          <div className="col-12">
            <strong>Tags</strong> | {tags}
          </div>
        </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    selected: getSelected(state),
    token: getAuthToken(state)
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    requestContent,
    updateSelection
  }, dispatch)
};

const Tags = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsComponent);

export default withRouter(Tags);