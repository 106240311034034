import React, {Component} from 'react';
import {connect} from 'react-redux';
import {addAnchor} from "../../actions/contentSelection";
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

class TimelineAnchorButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.navigateToAnchor = this.navigateToAnchor.bind(this);
  }

  navigateToAnchor() {
    this.props.addAnchor(this.props.anchor);
    this.props.history.push('/timeline');
  }

  render() {
    return (
      <div className="btn-group">
        <a onClick={this.navigateToAnchor} className="btn btn-link brand-link">TIMELINE</a>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addAnchor
  }, dispatch)
};

const TimelineAnchorButton = connect(
  () => {
    return {}
  },
  mapDispatchToProps
)(TimelineAnchorButtonComponent);

export default withRouter(TimelineAnchorButton);
