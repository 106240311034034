import React, {Fragment} from 'react';
import Content from "../ContentList/ContentList";
import settings from "../../../settings";
import Home from "../Home/Home";
import SelectModePage from "../SelectModePage/SelectModePage";
import ContentDetail from "../ContentDetail/ContentDetail";
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Loadable from "react-loadable";
import ContactPage from "../ContactPage/ContactPage";
import {Route} from 'react-router-dom';

const Timeline = Loadable({
  loader: () => import('../../../features/timeline/index'),
  loading: () => <div>Loading...</div>,
});

const Utilities = Loadable({
  loader: () => import('../../../features/utilities/index'),
  loading: () => <div>Loading...</div>,
});

const Routes = props => {
  return (
    <Fragment>
      <ProtectedRoute
        path="/"
        component={Home}
        authorized={!props.loggedIn}
        redirect="/select"
        exact />
      <ProtectedRoute
        path="/select"
        component={SelectModePage}
        authorized={props.loggedIn}
        redirect="/"
        exact />
      <ProtectedRoute
        path="/timeline"
        component={Timeline}
        authorized={props.loggedIn}
        redirect="/"
        exact />
      <ProtectedRoute
        path="/utilities"
        component={Utilities}
        authorized={props.loggedIn}
        redirect="/"
        exact />
      <ProtectedRoute
        path={'/' + settings.contentSlug}
        component={Content}
        authorized={props.loggedIn}
        redirect="/"
        exact />
      <ProtectedRoute
        path={'/' + settings.contentSlug + '/:id'}
        component={ContentDetail}
        authorized={props.loggedIn}
        redirect="/"
        exact />
      <Route
        path="/contact"
        component={ContactPage}
        exact />
    </Fragment>
  )
};

export default Routes;