import React, {Fragment} from 'react';
import settings from '../../../settings';
import Tags from '../Tags/Tags';
import TimelineAnchorButton from "../TimelineAnchorButton/TimelineAnchorButton";

const ContentSummmary = (props) => {
  const {item: {
    id,
    full_id,
    images,
    artist,
    title,
    medium,
    year,
    size,
    edition,
    gallery,
    house,
    room,
    tags
  }} = props;

  const onImageClick = props.onImageClick ? props.onImageClick :  () => {};
  const formattedGallery = gallery ? gallery.name.split(' (')[0] : null;

  let imgs = [];
  let hero = '';

  if (images) {
    const imageKeys = Object.keys(images);

    if (!imageKeys.length) {
      return false;
    }
    // hero
    hero = images[imageKeys[0]]['MO'] ? images[imageKeys[0]]['MO'][0].file_path : '/assets/theme/KC-No-Image.png';
    // thumbnails
    imgs = imageKeys.map((image, index) => {
      const thumb = images[image]['TH'] ? images[image]['TH'][0].file_path : '/assets/theme/KC-No-Image.png';

      return (
        <li key={index}>
          <button onClick={() => onImageClick(index)}>
            <img src={settings.internal.imageEndpoint + thumb} />
          </button>
        </li>
      );

    });
  }


  return (
    <div id={full_id} className="summary row">

      <div className="col-xl-7 image">
        {hero &&
        <img onClick={() => onImageClick(0)} src={settings.internal.imageEndpoint + hero}/>
        }
      </div>

      <div className="col-xl-5 details">
        <div className="wrap">
          {(artist && artist.length > 0) &&
          <div className="artist">{artist[0].name}</div>
          }
          <div className="summary-info">
            {(title || year) &&
            <div className="title-year">
              {title &&
              <span className="title">{title}</span>
              }
              {(title && year) && <span>, </span>}
              {year &&
              <span className="year">{year}</span>
              }
            </div>
            }
            {medium &&
            <div className="medium">{medium}</div>
            }
            {(size || edition) &&
            <div className="size-edition">
              {size && <span className="dimensions">{size}</span>}
              {(size && edition) && <span> | </span>}
              {edition && <span className="edition">{edition}</span>}
            </div>
            }
            {gallery &&
            <div className="gallery-location-group">
              <span className="gallery">{formattedGallery}</span>
              {gallery.city_state &&
              <Fragment>
                <span> | </span>
                <span className="gallery-location">{gallery.city_state}</span>
              </Fragment>
              }
            </div>
            }
            {(house || room) &&
            <div className="location">
              {house &&
              <span className="house">{house.name}</span>
              }
              {(house && room) && <span> | </span>}
              {room &&
              <span className="room">{room.name}</span>
              }
            </div>
            }
            {full_id &&
            <div className="full-id">{full_id}</div>
            }
          </div>

          <button className="btn btn-link details-button brand-link">Details</button>

          {(tags && tags.length > 0) &&
          <Tags detailVisible={props.detailVisible} tags={tags} />
          }

          {(props.detailVisible && imgs.length > 0) &&
          <div className="images">
            <ul className="">
              {imgs}
            </ul>
          </div>
          }

          {!props.hideLinks &&
          <TimelineAnchorButton anchor={id}/>
          }

        </div>
      </div>
    </div>
  )
};

export default ContentSummmary;