import {clearContent, clearContentDetail} from "./content";
import {updateSelection} from "./contentSelection";
import {emptySelection} from "../reducers/contentSelection";

export const clearAuth = dispatch => {
  localStorage.removeItem('app-accounts');
  dispatch(updateSelection(emptySelection()));
  dispatch(clearContent());
  dispatch(clearContentDetail());
  window.location = '/';
};