const API_ROOT = process.env.REACT_APP_API_ROOT || '';

const settings = {
  endpoint: {
    login: API_ROOT + '/api/login',
    logout: API_ROOT + '/api/logout',
    forgotPassword: API_ROOT + '/api/forgot',
    updatePassword: API_ROOT + '/api/update-password',
    register: API_ROOT + '/api/register',
  },
  internal: {
    adminEndpoint: API_ROOT + '/',
    loginEndpoint: API_ROOT + '/api/login',
    logoutEndpoint: API_ROOT + '/api/logout',
    contentEndpoint: API_ROOT + '/api/collection',
    searchEndpoint: API_ROOT + '/api/search',
    saveEndpoint: API_ROOT + '/api/collection',
    resetPasswordEndpoint: API_ROOT + '/api/forgot',
    updatePasswordEndpoint: API_ROOT + '/api/update-password',
    registerEndpoint: API_ROOT + '/api/register',
    imageEndpoint: API_ROOT + '/storage/',
    documentEndpoint: API_ROOT + '/storage/',
    timelineEndpoint: API_ROOT + '/api/timeline',
    optionsEndpoint: API_ROOT + '/api/options',
    reportEndpoint: API_ROOT + '/api/report',
    downloadReportEndpoint: API_ROOT + '/api/'
  },
  loggedInRedirect: '/catalog',
  contentSlug: 'catalog',
  delimiter: '|',
  defaultImagePath: '/assets/theme/KC-No-Image.png'
};

export default settings;