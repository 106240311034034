import React from 'react';
import MainNav from '../MainNav/MainNav';
import Search from '../Search/Search';
import {withSelectRedirect} from '../SelectRedirect/SelectRedirect';
import './Header.css';
import settings from "../../../settings";
import {withRouter} from 'react-router-dom';
import SelectToggler from '../SelectToggler/SelectToggler';
import ContentSelect from '../ContentSelect/ContentSelect';

const Header = (props) => {

  const LogoLink = withSelectRedirect(
    (props) => {
      return (
        <a className="home-link" onClick={() => props.handleSelectRedirect()}>
          <h1 className={(props.authed ? 'blue':'')}>The Kennedy Collection</h1>
        </a>
      )
    },
    {authed: props.authed}
  );

  const showNav = props.location.pathname !== '/select';
  const showSelectorDropdown = props.visibleSelector !== undefined && props.visibleSelector !== '';
  const isMobile = window.innerWidth < 768;

  return (
    <header>
      <div className="wrap container-fluid">
        <div className="row">
          <div className="col-sm-8">
            <LogoLink select={{}} redirect={'/' + settings.contentSlug} />
          </div>
          { (showNav && props.authed) &&
            <div className="col-sm-4 search">
              <Search />
            </div>
          }
        </div>
      </div>
      {props.authed &&
      <React.Fragment>
        <div className="main-nav">
          <div className="wrap container-fluid">
            <div className="top-nav row">
              {showNav &&
              <SelectToggler/>
              }
              {(showNav && showSelectorDropdown && !isMobile) &&
              <div className="dropdown-container col-sm-3">
                <ContentSelect/>
              </div>
              }
              <div className="nav col-8">
                <div className="row">
                  <MainNav showFullNav={showNav}/>
                </div>
              </div>
            </div>
            {(showNav && showSelectorDropdown && isMobile) &&
            <div className="bottom-nav row">
              <div className="dropdown-container col-sm-10">
                <ContentSelect/>
              </div>
            </div>
            }
          </div>
        </div>
      </React.Fragment>
      }
    </header>
  );
};

export default withRouter(Header);