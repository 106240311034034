import React, {Component, Fragment} from 'react';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Header from '../Header/Header';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Routes from "../Routes/Routes";
import {getActiveSelectName} from "../../reducers";
import {updateAuth} from "../../actions/auth/rest.api";
import {getAuthenticated, getAuthLoading} from '../../reducers/auth';
import {clearAuth} from '../../actions';

class App extends Component {
  componentWillMount() {
    this.props.updateAuth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authenticated !== this.props.authenticated && !this.props.authenticated) {
      clearAuth(this.props.dispatch);
    }
  }

  render() {
    return (
      <ScrollToTop>
        <div id="wrapper">

          {!this.props.authLoading &&
          <Fragment>
            <Header authed={this.props.authenticated} visibleSelector={this.props.visibleSelector} />
            <main>
              <Routes loggedIn={this.props.authenticated} />
            </main>
          </Fragment>
          }

          <footer>
            <span>© 2019 WKCollection.com</span>
          </footer>

        </div>
      </ScrollToTop>
    );
  }
}

export default connect(
  state => {
    return {
      visibleSelector: getActiveSelectName(state),
      authenticated: getAuthenticated(state),
      authLoading: getAuthLoading(state)
    }
  },
  dispatch => bindActionCreators({
    updateAuth,
    dispatch
  }, dispatch)
)(App);
