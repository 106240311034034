import React from 'react';
import {
  REQUEST_TIMELINE_PENDING,
  REQUEST_TIMELINE_SUCCESS,
  REQUEST_TIMELINE_FAIL,
  CLEAR_TIMELINE
} from './actions';
import * as fromContentSelection from '../../core/reducers/contentSelection';
import * as fromAuth from '../../core/reducers/auth';

export const defaultTimelineState = {
  pending: false,
  timeline: null,
  error: null
};

export const reducer = (state = defaultTimelineState, action) => {
  switch(action.type) {
    case REQUEST_TIMELINE_PENDING:
      return {...state, pending: true};
    case REQUEST_TIMELINE_SUCCESS:
      return {...state, pending: false, timeline: action.timeline};
    case REQUEST_TIMELINE_FAIL:
      return {...state, pending: false, error: action.error};
    case CLEAR_TIMELINE:
      return {...state, timeline: null};
    default:
      return state;
  }
};

export const getTimeline = state => {
  return state.timeline.timeline;
};

export const getLoading = state => {
  return state.timeline.loading;
};

export const getAnchor = state => {
  return fromContentSelection.getAnchor(state);
};

export const getSelected = state => {
  return fromContentSelection.getSelected(state);
};

export const getAuthToken = state => {
  return fromAuth.getAuthToken(state);
};