import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateSelection} from '../../actions/contentSelection';
import {requestContent} from '../../actions/content';
import {emptySelection, getSelected} from '../../reducers/contentSelection';
import {bindActionCreators} from 'redux';
import FontAwesome from 'react-fontawesome';
import 'react-select/dist/react-select.css';
import '../selectors/Selector.css';
import {getAuthToken} from "../../reducers/auth";

class SelectionComponent extends Component {
  constructor(props) {
    super(props);
    this.handleDeselect = this.handleDeselect.bind(this);
  }

  handleDeselect(event, item) {

    const selection = Object.keys(this.props.selected).reduce((accumulator, current) => {
      const selected = this.props.selected[current].filter(i => {
        const s = i.value !== item.value;
        return s;
      });
      accumulator[current] = selected || [];
      return accumulator;
    }, {});

    this.props.updateSelection(selection);
    this.props.requestContent(selection, this.props.token);
  }

  render() {
    if (!this.props.selected) {
      return false;
    }

    const selected = Object.keys(this.props.selected)
      .filter(key => key !== 'category')
      .map(key => {
        return this.props.selected[key].map((item, index) => {
          return (
            <li key={index}>
              <span className="type">{key}</span>:&nbsp;
              {item.parent &&
              <span className="parent">{item.parent}</span>
              }
              { (item.label && item.parent) &&
              <span>, </span>
              }
              {item.label &&
              <span>{item.label}</span>
              }
              <a className="button-close" onClick={(e) => this.handleDeselect(e, item)}><FontAwesome name="close" /></a>
            </li>
          );
        })
    });


    return(
      <div className="selection wrap container-fluid">
        <div className="row">
          <div className="col-12">
            <ul>{selected}</ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {

  return {
    selected: getSelected(state),
    token: getAuthToken(state)
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    requestContent,
    updateSelection
  }, dispatch)
};

const Selection = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionComponent);

export default Selection;