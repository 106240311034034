import React, {Component} from 'react';
import Selection from '../Selection/Selection';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {requestContent} from '../../actions/content';
import ContentSummary from '../ContentSummary/ContentSummary';
import settings from '../../../settings';
import FontAwesome from 'react-fontawesome';
import './ContentList.css';
import LazyLoad from 'react-lazyload';
import {bindActionCreators} from "redux";
import {clearContent} from '../../actions/content';
import {getCollection, getClearCache} from "../../reducers/content";
import {getSearchTerm, getSelected} from "../../../core/reducers/contentSelection";
import {getAuthToken} from "../../reducers/auth";
import isEqual from 'lodash/isEqual';
import {getSelectedCategories} from "../../reducers";

class ContentList extends Component {

  componentWillMount() {
    const {selected, searchTerm, token, clearContent, requestContent} = this.props;
    clearContent();
    requestContent({...selected, search: searchTerm}, token, searchTerm ? settings.internal.searchEndpoint : null);
  }

  componentDidUpdate(prevProps) {
    const {selected, searchTerm, token, selectedCategories, requestContent, clearContent} = this.props;
    if (!isEqual(prevProps.selected, selected)
      || !isEqual(prevProps.selectedCategories, selectedCategories)
      || !isEqual(prevProps.searchTerm, searchTerm)) {
      clearContent();
      requestContent({...selected, search: searchTerm}, token, searchTerm ? settings.internal.searchEndpoint : null);
    }
  }

  render() {
    const {content} = this.props;

    if (!content) {
      return (<div className="loading"><FontAwesome name="spinner" className="fa-spin" /></div>);
    }

    const listItems = content.map((item) => {
      const url = '/' + settings.contentSlug + '/' + item.id;
      return (
          <li key={item.id} className="col-sm-6">
            <Link to={url}>
              {(content.length > 10) &&
              <div className="lazy">

                <LazyLoad height={200} offset={500}>
                  <ContentSummary item={item} hideLinks={true} />
                </LazyLoad>

              </div>
              }
              {(content.length <= 10) &&
              <ContentSummary item={item} hideLinks={true} />
              }
            </Link>
          </li>
      );
    });

    return (
      <div className="content wrap">
        <ul className="row">
          <Selection />
          {listItems}
        </ul>

      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    selected: getSelected(state),
    selectedCategories: getSelectedCategories(state),
    searchTerm: getSearchTerm(state),
    content: getCollection(state),
    clearCache: getClearCache(state),
    token: getAuthToken(state)
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    clearContent,
    requestContent
  }, dispatch)
};

const Content = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentList);

export default Content;