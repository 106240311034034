import React from 'react';
import {staticPage} from "../Page/Page";
import SelectMode from '../SelectMode/SelectMode';

const SelectModePageComponent = (page) => {

  return (
    <SelectMode />
  );
};

const SelectModePage = staticPage(SelectModePageComponent);

export default SelectModePage;