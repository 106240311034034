import {mapSelectionToParams, fetchContent} from "../../core/api/index";
import {updateOptions} from '../../core/actions/contentSelection';
import settings from "../../settings";
import {buildParams} from "../../core/api";
import {clearAuth} from "../../core/actions";
import {requestContentFail} from "../../core/actions/content";

export const REQUEST_TIMELINE_PENDING = 'REQUEST_TIMELINE_PENDING';
export const REQUEST_TIMELINE_SUCCESS = 'REQUEST_TIMELINE_SUCCESS';
export const REQUEST_TIMELINE_FAIL = 'REQUEST_TIMELINE_FAIL';
export const CLEAR_TIMELINE = 'CLEAR_TIMELINE';

export const requestTimeline = (selected, token) => {
  return async dispatch => {
    dispatch(requestTimelinePending());

    try {
      const params = mapSelectionToParams(selected);
      const formattedParams = buildParams(params);
      const response = await fetchContent(formattedParams, token, settings.internal.timelineEndpoint);

      if (!response.ok) {
        if (response.status === 401) {
          clearAuth(dispatch);
        }
        else {
          dispatch(requestContentFail(response.message));
        }
      }
      else {
        const timeline = await response.json();
        dispatch(requestTimelineSuccess(timeline.data.collection));
        dispatch(updateOptions(timeline.data.options))
      }
    }
    catch (error) {
      dispatch(requestTimelineFail(error))
    }
  }
};

export const requestTimelinePending = () => {
  return {
    type: REQUEST_TIMELINE_PENDING
  }
};

export const requestTimelineSuccess = timeline => {
  return {
    type: REQUEST_TIMELINE_SUCCESS,
    timeline
  }
};

export const requestTimelineFail = error => {
  return {
    type: REQUEST_TIMELINE_FAIL,
    error
  }
};

export const clearTimeline = () => {
  return {
    type: CLEAR_TIMELINE
  }
};